import { v4 as uuid } from "uuid";
import { fields } from "@monorepo/utils/src/variables/projectsData/bugReportView/fields";
import { headerCheckboxObject } from "@monorepo/utils/src/variables/headerCheckboxValue";

export const tableHeaders = [
  headerCheckboxObject(),
  {
    text: "Идентификатор",
    defaultWidth: 140,
    value: fields.BUG_REPORT_ID,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Дата поступления",
    defaultWidth: 150,
    value: fields.BUG_REPORT_DATE,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Статус",
    value: fields.BUG_REPORT_STATUS_TEXT,
    defaultWidth: 150,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Сообщение",
    value: fields.BUG_REPORT_MESSAGE,
    defaultWidth: 250,
    isSorted: false,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Пользователь",
    value: fields.BUG_REPORT_USER,
    isSorted: true,
    defaultWidth: 150,
    isHandle: true,
    id: uuid(),
  },
];
