import { IBugReport } from "@monorepo/monitoring/src/views/BugReportListView/types/BugReport";

export const convertSaveCardObject = (resultData: Partial<IBugReport>): { [key: string]: unknown } => {
  return {
    id: resultData.id || "",
    username: resultData.username || "",
    message: resultData.message || "",
    status: resultData.status || "NOT_SOLVED",
  };
};
