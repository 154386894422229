import { fields } from "@monorepo/utils/src/variables/projectsData/bugReportView/fields";

export const currentFilters = (): Record<string, unknown> => ({
  [fields.BUG_REPORT_DATE_FROM]: "",
  [fields.BUG_REPORT_DATE_TO]: "",
  [fields.BUG_REPORT_TIME_FROM]: "",
  [fields.BUG_REPORT_TIME_TO]: "",
  [fields.BUG_REPORT_USER]: "",
  [fields.BUG_REPORT_STATUS]: "",
  [fields.BUG_REPORT_ID]: "",
});
