



























































































import { defineComponent, ref } from "@vue/composition-api";
import CardModalInfoElement from "@monorepo/uikit/src/components/tableCardModal/CardModalInfoElement.vue";
import FormInputElement from "@monorepo/uikit/src/components/tableCardModal/FormInputElement.vue";
import SelectFilter from "@monorepo/uikit/src/components/common/Select/SelectFilter.vue";
import { mapActions, mapGetters } from "vuex";
import { IBugReport } from "@monorepo/monitoring/src/views/BugReportListView/types/BugReport";
import {
  baseModalElements,
  fullModalElements as modalElements,
  techElements,
} from "@monorepo/utils/src/variables/projectsData/bugReportView/modalElements";
import { convertDataItemToTableView } from "@monorepo/monitoring/src/views/BugReportListView/utils/convertDataItemToTableView";
import { viewUniqKey } from "@monorepo/utils/src/variables/projectsData/bugReportView/viewTitle";
import eventBus from "@monorepo/utils/src/eventBus";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";
import { fields } from "@monorepo/utils/src/variables/projectsData/bugReportView/fields";
import CardModalStatusChip from "@monorepo/uikit/src/components/tableCardModal/CardModalStatusChip.vue";
import Screenshot from "@monorepo/monitoring/src/views/BugReportListView/components/Screenshot.vue";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { NOTIFICATION_STATUS } from "@monorepo/utils/src/eventBus/types/notification";
import { convertSaveCardObject } from "@monorepo/monitoring/src/views/BugReportListView/utils/convertSaveCardObject";
import { ModalType } from "@monorepo/utils/src/variables/modalType";
import { Statuses } from "@monorepo/monitoring/src/views/BugReportListView/constants/bugReportStatuses";
import { CardMode } from "@monorepo/utils/src/types/cardMode";
import { Sections } from "@monorepo/utils/src/types/cellsSections";
import CardModalToggleViewSize from "@monorepo/uikit/src/components/tableCardModal/CardModalToggleViewSize.vue";
import CardModalToggleTableItems from "@monorepo/uikit/src/components/tableCardModal/CardModalToggleTableItems.vue";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import CardModalChapter from "@monorepo/uikit/src/components/tableCardModal/CardModalChapter.vue";
import useCheckChangesModal from "@monorepo/utils/src/composables/useCheckChangesModal";
import useModalChangeByKeypress from "@monorepo/utils/src/composables/useModalChangeByKeypress";
import useUniqueLinkModal from "@monorepo/utils/src/composables/useUniqueLinkModal";

export default defineComponent({
  name: "BugReportInfoModal",
  components: {
    CardModalInfoElement,
    FormInputElement,
    CardModalStatusChip,
    CardModalToggleViewSize,
    CardModalToggleTableItems,
    Screenshot,
    SelectFilter,
    CardModalChapter,
  },
  data() {
    return {
      modalElements,
      baseModalElements,
      techElements,
      viewUniqKey,
      openedPanels: [0],
      fields,
      cardMode: CardMode,
      section: Sections.BUG_REPORT,
      isShowScreenshot: false,
      screenUrl: "",
      isLoading: false,
    };
  },
  props: {
    type: {
      type: String,
      default: ModalType.EDIT,
    },
  },
  watch: {
    openedId: {
      immediate: true,
      async handler(
        this: {
          addQueryOpenedId: () => void;
          getBugReportElement: (val: string) => Promise<IBugReport>;
          formValues: ReturnType<typeof convertDataItemToTableView>;
          changedFormValues: Record<string, unknown>;
          getResultSaveData: (data: Partial<IBugReport>) => { [key: string]: unknown };
          getScreenshot: (id: string) => string;
          screenUrl: string;
          isLoading: boolean;
          isShowAnimation: boolean;
        },
        value
      ) {
        try {
          this.isLoading = true;
          this.screenUrl = "";
          if (value) {
            await this.addQueryOpenedId();
            const data: IBugReport = await this.getBugReportElement(value);
            this.formValues = convertDataItemToTableView(data || {});
            this.changedFormValues = this.getResultSaveData(this.formValues);
            this.screenUrl = await this.getScreenshot(value);
          } else {
            this.changedFormValues = this.getResultSaveData({});
          }
        } catch (e) {
          console.error(e);
        } finally {
          this.isLoading = false;
        }
      },
    },
  },
  computed: {
    ...mapGetters("auth", ["user", "isShowAnimation", "cardModeList"]),
    ...mapGetters("bugReportView", ["data", "openedId", "statusesLib"]),
    cardModeResult(): CardMode {
      return this.cardModeList[this.section as string] || CardMode.DEFAULT;
    },
    statusValue: {
      set(value: string) {
        if (value) {
          this.formValues[fields.BUG_REPORT_STATUS as keyof IBugReport] = value;
        } else {
          this.formValues[fields.BUG_REPORT_STATUS as keyof IBugReport] = Object.keys(Statuses)[1];
        }
      },
      get(): string {
        return this.formValues[fields.BUG_REPORT_STATUS as keyof IBugReport] as string;
      },
    },
    status(): string {
      return this.formValues?.status ? Statuses[this.formValues?.status]?.toLowerCase() || "" : "";
    },
    statusColor(): string {
      switch (this.status) {
        case "решено":
          return "#00A459";
        case "не решено":
          return "#D34039";
        default:
          return "";
      }
    },
    toggleScreenshotTitle(): string {
      return this.isShowScreenshot ? "Закрыть снимок экрана" : "Открыть снимок экрана";
    },
    toggleScreenshotIcon(): string {
      return this.isShowScreenshot ? "mdi-arrow-right" : "mdi-arrow-left";
    },
    isShowTechData(): boolean {
      return isAuthorityExist(this.user, authorities.TECH_INFO);
    },
  },
  methods: {
    ...mapActions("bugReportView", ["getScreenshot", "changeOpenedId", "getBugReportElement", "saveCard", "refreshScroll"]),
    openModal() {
      eventBus.$emit(MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL, formModalPayload(true, "ModalUnderConstruction"));
    },
    closeModal() {
      if (this.type !== ModalType.READ) {
        const isClose = (this as unknown as { checkChangesBeforeClose: () => boolean }).checkChangesBeforeClose();
        if (isClose) {
          return void this.$emit("close");
        }
        return;
      }
      this.$emit("close");
    },
    toggleScreenshot() {
      this.isShowScreenshot = !this.isShowScreenshot;
    },
    getResultSaveData(data: Record<string, string | boolean>) {
      const resultData: { [key: string]: string | boolean } = {
        id: data.id || this.formValues.id,
        ...data,
      };
      return convertSaveCardObject(resultData);
    },
    async onSave(data: Record<string, string | boolean>) {
      const isNeedSave = (this as unknown as { checkChangesBeforeSave: () => boolean }).checkChangesBeforeSave();
      if (isNeedSave) {
        const resultData = this.getResultSaveData(data);
        const isSaved = await this.saveCard(resultData);
        if (isSaved) {
          (this as unknown as { changedFormValues: Record<string, unknown> }).changedFormValues = resultData;
          showNotification("Данные успешно сохранены.", NOTIFICATION_STATUS.SUCCESS);
          this.refreshScroll();
          this.closeModal();
        }
      } else {
        return;
      }
    },
  },
  setup(props, context) {
    const { root } = context;
    const formValues = ref({} as ReturnType<typeof convertDataItemToTableView>);
    const changedFormValues = ref({});
    const { checkChangesBeforeClose, checkChangesBeforeSave } = useCheckChangesModal(context, formValues, changedFormValues, convertSaveCardObject);
    useModalChangeByKeypress(root, "bugReportView", Sections.BUG_REPORT, props.type);
    const { addQueryOpenedId } = useUniqueLinkModal(root, "bugReportView");

    return {
      formValues,
      changedFormValues,
      addQueryOpenedId,
      checkChangesBeforeClose,
      checkChangesBeforeSave,
    };
  },
});
