


































































import InfiniteScroll from "@monorepo/uikit/src/components/tableViews/InfiniteScroll.vue";
import Table from "@monorepo/uikit/src/components/common/Table.vue";
import TableHeader from "@monorepo/uikit/src/components/tableViews/TableHeader.vue";
import Filters from "@monorepo/monitoring/src/views/BugReportListView/components/Filters.vue";
import SortPanelContainer from "@monorepo/monitoring/src/views/BugReportListView/components/SortPanelContainer.vue";
import BugReportInfoModal from "@monorepo/monitoring/src/views/BugReportListView/components/BugReportInfoModal.vue";
import Breadcrumbs from "@monorepo/uikit/src/components/common/Breadcrumbs.vue";
import ScrollPanel from "@monorepo/uikit/src/components/tableViews/ScrollPanel.vue";
import { defineComponent, ref } from "@vue/composition-api";
import useInitTableStoreModule from "@monorepo/utils/src/store/composables/useInitTableStoreModule";
import { module as bugReportView } from "@monorepo/monitoring/src/store/modules/bugReportView";
import eventBus from "@monorepo/utils/src/eventBus";
import useGetTableList from "@monorepo/monitoring/src/views/BugReportListView/composables/useGetTableList";
import useGetTableLibs from "@monorepo/monitoring/src/views/BugReportListView/composables/useGetTableLibs";
import useSetStartFilters from "@monorepo/utils/src/composables/useSetStartFilters";
import { tableHeaders } from "@monorepo/utils/src/variables/projectsData/bugReportView/tableHeaders";
import { viewTitle, viewUniqKey } from "@monorepo/utils/src/variables/projectsData/bugReportView/viewTitle";
import { mapActions, mapGetters, Module } from "vuex";
import { breadcrumbs } from "@monorepo/monitoring/src/views/BugReportListView/constants/breadcrumbs";
import { MONITORING_EVENT_BUS_EVENTS } from "@monorepo/utils/src/eventBus/events/monitoringEvents";
import TableSettings from "@monorepo/uikit/src/components/tableViews/TableSettings.vue";
import ToggleTableCellsContainer from "./components/ToggleTableCellsContainer.vue";
import ExportButton from "@monorepo/uikit/src/components/tableViews/ExportButton.vue";
import useInitEducationPanel from "@monorepo/utils/src/composables/useInitEducationPanel";
import useSetStartModal from "@monorepo/utils/src/composables/useSetStartModal";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import useSetStartCells from "@monorepo/utils/src/composables/useSetStartCells";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import useOpenHistoryMode from "@monorepo/utils/src/composables/useOpenHistoryMode";
import { fields } from "@monorepo/utils/src/variables/projectsData/bugReportView/fields";
import { convertApiItemToUi } from "@monorepo/monitoring/src/views/BugReportListView/utils/convertDataItemToTableView";

const SECTION_NAME = "bugReportView";

export default defineComponent({
  name: "BugReportListView",
  components: {
    TableSettings,
    ToggleTableCellsContainer,
    ExportButton,
    Table,
    Filters,
    InfiniteScroll,
    ScrollPanel,
    TableHeader,
    Breadcrumbs,
    SortPanelContainer,
    BugReportInfoModal,
  },
  data() {
    return {
      breadcrumbs,
      viewUniqKey,
      viewTitle,
      fields,
      tableHeaders,
      convertApiItemToUi,
    };
  },
  computed: {
    ...mapGetters("auth", ["isOpenEducation", "user"]),
    ...mapGetters(SECTION_NAME, [
      "isOpenFilters",
      "cells",
      "isSelectAll",
      "data",
      "openedId",
      "infiniteId",
      "selectedIds",
      "totalLength",
      "isTableLoading",
    ]),
    isShowExport(): boolean {
      return isAuthorityExist(this.user, authorities.ERROR_MSG_EXPORT);
    },
    headers(): typeof tableHeaders {
      return tableHeaders;
    },
    isShowModal(): boolean {
      return !this.isOpenEducation && Boolean(this.openedId);
    },
    isSelectAllElement: {
      get(): boolean {
        return this.isSelectAll;
      },
      set(value: boolean) {
        this.$store.dispatch(`${SECTION_NAME}/setIsSelectAll`, value);
      },
    },
    additionalClassObj() {
      return (index: number, event: Record<string, unknown>) => {
        return {
          "monitoring-table__td_first": !index,
          "monitoring-table__td_success": !index && event[fields.BUG_REPORT_STATUS] === "SOLVED",
          "monitoring-table__td_error": !index && event[fields.BUG_REPORT_STATUS] === "NOT_SOLVED",
        };
      };
    },
  },
  methods: {
    ...mapActions(SECTION_NAME, ["getEventList", "getExportData", "setIsOpenFilters"]),
    getEventListFinish() {
      eventBus.$emit(MONITORING_EVENT_BUS_EVENTS.REFRESH_EVENTS_FINISH);
    },
    toggleModal(value: boolean) {
      if (!value) {
        this.$store.dispatch(`${SECTION_NAME}/addOpenedId`, null);
      }
    },
    openModal({ header, event }: { header: { value: string; isLink: boolean }; event: Record<string, unknown> }) {
      switch (header.value) {
        case "openModal":
          if (event.id) {
            this.$store.dispatch(`${SECTION_NAME}/addOpenedId`, event.id);
            this.toggleModal(true);
          }
          break;
        default:
          break;
      }
    },
    setCells(value: { value: string }[]) {
      this.$store.dispatch(
        `${SECTION_NAME}/setCells`,
        value.map((item) => item.value)
      );
    },
  },
  setup(props, { root }) {
    const sectionName = ref(SECTION_NAME); // sectionName === store module name
    const filtersLoaded = ref(false);

    useInitTableStoreModule(root, SECTION_NAME, bugReportView as Module<unknown, unknown>);
    useOpenHistoryMode(root, SECTION_NAME);
    useSetStartCells(root, SECTION_NAME, root.$store.getters[`${SECTION_NAME}/section`], tableHeaders);
    useSetStartFilters(root, SECTION_NAME, ["id"]).then(() => {
      useInitEducationPanel(root);
      filtersLoaded.value = true;
      useSetStartModal(root, SECTION_NAME);
    });
    useGetTableList(root);
    useGetTableLibs(root);

    return {
      filtersLoaded,
      sectionName,
    };
  },
});
