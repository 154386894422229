import { fields } from "@monorepo/utils/src/variables/projectsData/bugReportView/fields";

export const filtersElements = [
  {
    value: fields.BUG_REPORT_DATE,
    title: "Период получения сообщения",
  },
  {
    value: fields.BUG_REPORT_USER,
    title: "Пользователь",
  },
  {
    value: fields.BUG_REPORT_STATUS,
    title: "Статус",
  },
  {
    value: fields.BUG_REPORT_ID,
    title: "Идентификатор сообщения",
  },
];
