import { IBugReport } from "@monorepo/monitoring/src/views/BugReportListView/types/BugReport";
import moment from "moment";
import { Statuses } from "@monorepo/monitoring/src/views/BugReportListView/constants/bugReportStatuses";

export const convertDataItemToTableView = (item: IBugReport): IBugReport => {
  return {
    ...item,
    createDate: moment(item.createDate).format("YYYY-MM-DD HH:mm"),
    updateDate: moment(item.updateDate).format("YYYY-MM-DD HH:mm"),
  };
};

export const convertApiItemToUi = (item: IBugReport): IBugReport => {
  return {
    ...convertDataItemToTableView(item),
    statusText: item.status ? Statuses[item.status] || "" : "",
  };
};
