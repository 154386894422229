import { Module } from "vuex";
import { IRootState } from "@monorepo/monitoring/src/store";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import { checkExistLibrary, encodeQueryData, getFullPath, getQuery } from "@monorepo/utils/src/api/utils";
import { convertFiltersCustomToApi } from "@monorepo/monitoring/src/views/BugReportListView/utils/convertFiltersToApi";
import { SORT_TYPE } from "@monorepo/utils/src/types/sortTypes";
import { IBugReport } from "@monorepo/monitoring/src/views/BugReportListView/types/BugReport";
import { ITableFiltersObject } from "@monorepo/utils/src/store/types/tableFiltersObject";
import { convertFiltersToApi, IConvertFiltersToApiResponse } from "@monorepo/utils/src/api/convertFiltersToApi";
import { mutations as baseMutations } from "@monorepo/utils/src/store/modules/mutations";
import { actions as baseActions } from "@monorepo/utils/src/store/modules/actions";
import { getters as baseGetters } from "@monorepo/utils/src/store/modules/getters";
import { ISection, Sections } from "@monorepo/utils/src/types/cellsSections";
import { ISearchTemplate } from "@monorepo/utils/src/types/ISearchTemplate";
import { Statuses } from "@monorepo/monitoring/src/views/BugReportListView/constants/bugReportStatuses";
import axios from "axios";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import store from "@monorepo/app/src/store";
import { IAuthState } from "@monorepo/authorization/src/store/modules/auth";
import { getSelectedIds } from "@monorepo/utils/src/utils/getSelectedIds";

interface ITableState {
  filters: ITableFiltersObject;
  libraries: {
    statuses: Record<string, string>[];
  };
  section?: Sections;
  sectionCells: ISection;
  isOpenFilters: boolean;
  searchTemplates: ISearchTemplate[];
  totalLength: number;
  data: IBugReport[];
  infiniteId: string;
  cells: string[];
  isTableLoading: boolean;
  isLoadingToggleFilters: boolean;
}

const defaultFilters = (): ITableFiltersObject => ({
  sort: {
    createDate: SORT_TYPE.DESC,
  },
  openedId: null,
  isSelectAll: false,
  selectedIds: {},
  fieldFilters: {
    status: [...Object.keys(Statuses)],
  },
  initMessagesLength: 50,
});

const resultExportPath = (payload: "PDF" | "CSV" | "XLSX") => {
  switch (payload) {
    case "CSV":
      return QUERY_PATH.GET_BUG_REPORT_CSV;
    case "XLSX":
      return QUERY_PATH.GET_BUG_REPORT_XLS;
    default:
      return QUERY_PATH.GET_BUG_REPORT_PDF;
  }
};

export const module: Module<ITableState, IRootState> = {
  namespaced: true,
  state: (): ITableState => ({
    libraries: {
      statuses: [],
    },
    section: Sections.BUG_REPORT,
    cells: [],
    searchTemplates: [],
    sectionCells: {} as ISection,
    filters: defaultFilters(),
    isOpenFilters: false,
    infiniteId: new Date().toString(),
    totalLength: 0,
    data: [],
    isTableLoading: false,
    isLoadingToggleFilters: false,
  }),
  mutations: {
    ...baseMutations,
    clearFilters(state: ITableState) {
      state.filters = defaultFilters();
    },
    addSort(state: ITableState, payload: Record<string, SORT_TYPE>) {
      state.filters.sort = { ...payload };
    },
    addData(
      state: ITableState,
      payload: {
        data: IBugReport[];
        totalLength: number;
      }
    ) {
      state.data = state.data.concat(payload.data);
      state.totalLength = payload.totalLength;
    },
    addStatusesLibItems(state: ITableState, payload: { title: string; value: string }[]) {
      state.libraries.statuses = payload || [];
    },
    setTableLoading(state: ITableState, payload: boolean) {
      state.isTableLoading = payload;
    },
  },
  actions: {
    ...baseActions,
    async getStatusesLib({ commit, state }) {
      checkExistLibrary(state.libraries.statuses, async () => {
        //const { data } = await getQuery<string[]>(QUERY_PATH.GET_OBJECTS_LIB);
        const data = [
          { title: "Решено", value: "SOLVED" },
          { title: "Не решено", value: "NOT_SOLVED" },
        ];
        commit("addStatusesLibItems", data);
      });
    },
    async getEventList({ commit, state, rootGetters }) {
      if (rootGetters["auth/isShowAnimation"]) {
        commit("setTableLoading", true);
      }
      const params = convertFiltersToApi(state.filters, state.data.length, convertFiltersCustomToApi);
      const {
        data,
        total: totalLength,
        error,
      } = await getQuery<IBugReport[]>(QUERY_PATH.GET_BUG_REPORT, params).finally(() => {
        commit("setTableLoading", false);
      });
      if (data !== null) {
        commit("addData", { data: data || [], totalLength: totalLength || 0 });
        return { data: state.data || [], totalLength: state.totalLength || 0 };
      }
      return { data: null, error };
    },
    async getBugReportElement(info, payload: string) {
      const { data } = await getQuery<IBugReport>(`${QUERY_PATH.GET_BUG_REPORT}/${payload}`);
      return data || {};
    },
    async saveCard(info, data: Record<string, unknown>): Promise<boolean> {
      try {
        await axios.put(getFullPath(`${QUERY_PATH.GET_BUG_REPORT}/${data.id}`), data).catch(function (error) {
          showNotification(error.response.data.message);
          return Promise.reject(error);
        });
        return true;
      } catch (e) {
        return false;
      }
    },
    async getScreenshot(info, payload: string) {
      const token = (store?.state?.auth as IAuthState)?.user?.details?.token;

      let screenUrl;
      await fetch(`${QUERY_PATH.GET_BUG_REPORT}/${payload}/screen`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then(async (response) => {
          const blob = await response.blob();
          screenUrl = URL.createObjectURL(blob);
        })
        .catch((err) => {
          console.log(err);
        });

      return screenUrl;
    },
    async getExportData({ state, dispatch }, payload: "CSV" | "PDF" | "XLSX") {
      let params: IConvertFiltersToApiResponse | { ids: string[] };
      const selectedKeys = state.filters.selectedIds ? getSelectedIds(state.filters.selectedIds) : [];
      if (selectedKeys.length && !state.filters.isSelectAll) {
        params = {
          ids: selectedKeys,
        };
      } else {
        params = convertFiltersToApi(state.filters, state.data.length, convertFiltersCustomToApi, true);
      }
      const queryParams = encodeQueryData(params);
      dispatch("app/openNewWindow", getFullPath(resultExportPath(payload), queryParams), {
        root: true,
      });
    },
  },
  getters: {
    ...baseGetters,
    statusesLib(state: ITableState) {
      return state.libraries.statuses;
    },
    isTableLoading(state: ITableState) {
      return state.isTableLoading;
    },
  },
};
