var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"backdrop",on:{"click":_vm.closeModal}},[_c('FormulateForm',{attrs:{"name":"bug-report-info-modal"},on:{"submit":_vm.onSave},model:{value:(_vm.formValues),callback:function ($$v) {_vm.formValues=$$v},expression:"formValues"}},[_c('div',{staticClass:"info-modal d-flex align-center justify-end",on:{"click":function($event){$event.stopPropagation();}}},[_c('transition',{attrs:{"name":"fade"}},[(_vm.isLoading && _vm.isShowAnimation)?_c('TmpLogoOverlay',{staticClass:"absolute"}):_vm._e()],1),(_vm.isShowScreenshot)?_c('div',{staticClass:"info-modal__pdf-wrap"},[_c('Screenshot',{attrs:{"screenUrl":_vm.screenUrl,"bugReportId":_vm.formValues[_vm.fields.BUG_REPORT_ID]},on:{"close":_vm.toggleScreenshot}})],1):_vm._e(),_c('div',{class:[
          'info-modal__content d-flex flex-column',
          {
            'info-modal__content_three-quarter': _vm.cardModeResult === _vm.cardMode.THREE_QUARTHER,
            'info-modal__content_full': _vm.cardModeResult === _vm.cardMode.FULL,
          } ]},[_c('CardModalStatusChip',{attrs:{"isLoading":_vm.isLoading,"background-color":_vm.statusColor,"status":_vm.status}}),_c('card-modal-toggle-view-size',{attrs:{"section":_vm.section}}),_c('div',{staticClass:"info-modal__close-wrap"},[_c('v-btn',{attrs:{"data-test-id":"closeBtn","icon":""},nativeOn:{"click":function($event){return _vm.closeModal($event)}}},[_c('v-icon',{attrs:{"color":"#21201F"}},[_vm._v("mdi-close")])],1)],1),(_vm.isLoading)?_c('TmpBlock',{staticClass:"info-modal__title ml-6 mr-6 mb-4 mt-12",attrs:{"height":"24"}}):[_c('div',{staticClass:"info-modal__title pl-6 pr-6 pb-4 pt-12",attrs:{"data-test-id":"header"}},[_c('card-modal-toggle-table-items',{on:{"toggle":_vm.changeOpenedId}}),_c('span',{staticClass:"ml-2"},[_vm._v("Карточка Сообщения об ошибке: "+_vm._s(_vm.formValues.id))])],1)],_c('div',{staticClass:"info-modal__content-wrap"},[_c('v-expansion-panels',{attrs:{"multiple":"","accordion":"","value":_vm.openedPanels},on:{"update:value":function($event){_vm.openedPanels=$event}}},[_c('CardModalChapter',{attrs:{"title":"Общая информация","modalElements":_vm.baseModalElements,"isLoading":_vm.isLoading,"formValues":_vm.formValues,"modalType":_vm.type},scopedSlots:_vm._u([{key:"selectStatus",fn:function(ref){
        var modalElement = ref.modalElement;
return [_c('div',{staticClass:"ead-info-element"},[(_vm.isLoading)?_c('TmpBlock',{staticClass:"mb-1",attrs:{"height":"20"}}):_c('v-tooltip',{attrs:{"z-index":"200","fixed":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"ead-info-element__title mb-1"},'div',attrs,false),on),[_vm._v(" "+_vm._s(modalElement.title)+" ")])]}}],null,true)},[_c('div',[_vm._v(" "+_vm._s(modalElement.tooltip)+" ")])]),_c('SelectFilter',{attrs:{"loading":_vm.isLoading,"id":_vm.fields.BUG_REPORT_STATUS,"title":"Не задан","items":_vm.statusesLib,"is-multiple":false},model:{value:(_vm.statusValue),callback:function ($$v) {_vm.statusValue=$$v},expression:"statusValue"}})],1)]}}])}),(_vm.isShowTechData)?_c('CardModalChapter',{attrs:{"title":"Техническая информация","modalElements":_vm.techElements,"isLoading":_vm.isLoading,"formValues":_vm.formValues,"modalType":_vm.type}}):_vm._e()],1)],1),_c('div',{staticClass:"pa-6 info-modal__footer"},[_c('v-btn',{staticClass:"info-modal__toggle-pdf-btn mr-2",attrs:{"loading":_vm.isLoading,"height":"40","color":"primary"},on:{"click":_vm.toggleScreenshot}},[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"20","color":"white"}},[_vm._v(_vm._s(_vm.toggleScreenshotIcon))]),_c('span',{staticClass:"info-modal__toggle-pdf-btn-text"},[_vm._v(_vm._s(_vm.toggleScreenshotTitle))])],1),_c('v-btn',{staticClass:"info-modal__btn",attrs:{"outlined":"","color":"primary","type":"submit","height":"40"}},[_c('span',[_vm._v("Сохранить")])])],1)],2)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }