import eventBus from "@monorepo/utils/src/eventBus";
import { MONITORING_EVENT_BUS_EVENTS } from "@monorepo/utils/src/eventBus/events/monitoringEvents";
import { onBeforeMount, onUnmounted, ComponentInstance } from "@vue/composition-api";

const useGetTableList = (root: ComponentInstance): void => {
  const store = root.$store;

  const refreshEventsListener = () => {
    store.dispatch("bugReportView/refreshScroll");
  };

  onBeforeMount(() => {
    eventBus.$on(MONITORING_EVENT_BUS_EVENTS.REFRESH_EVENTS, refreshEventsListener);
  });

  onUnmounted(() => {
    eventBus.$off(MONITORING_EVENT_BUS_EVENTS.REFRESH_EVENTS, refreshEventsListener);
  });
};

export default useGetTableList;
