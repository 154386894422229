export interface IBugReportListFields {
  BUG_REPORT_DATE: string;
  BUG_REPORT_DATE_FROM: string;
  BUG_REPORT_DATE_TO: string;
  BUG_REPORT_TIME_FROM: string;
  BUG_REPORT_TIME_TO: string;
  BUG_REPORT_USER: string;
  BUG_REPORT_STATUS: string;
  BUG_REPORT_ID: string;
  BUG_REPORT_MESSAGE: string;
  BUG_REPORT_UPDATE_USER: string;
  BUG_REPORT_STATUS_TEXT: string;
  BUG_REPORT_UPDATE_DATE: string;
}

export const fields: IBugReportListFields = {
  BUG_REPORT_DATE: "createDate",
  BUG_REPORT_DATE_FROM: "createDateFrom",
  BUG_REPORT_DATE_TO: "createDateTo",
  BUG_REPORT_TIME_FROM: "timeFrom",
  BUG_REPORT_TIME_TO: "timeTo",
  BUG_REPORT_USER: "username",
  BUG_REPORT_STATUS: "status",
  BUG_REPORT_STATUS_TEXT: "statusText",
  BUG_REPORT_ID: "id",
  BUG_REPORT_MESSAGE: "message",
  BUG_REPORT_UPDATE_USER: "updateUser",
  BUG_REPORT_UPDATE_DATE: "updateDate",
};
