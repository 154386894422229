import { v4 as uuid } from "uuid";
import { fields } from "@monorepo/utils/src/variables/projectsData/bugReportView/fields";
import { ModalType } from "@monorepo/utils/src/variables/modalType";

export const baseModalElements = [
  {
    id: uuid(),
    isEdited: false,
    title: "Идентификатор",
    tooltip: "Идентификатор сообщения об ошибке",
    className: "info-modal__element_title",
    value: fields.BUG_REPORT_ID,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Дата поступления",
    tooltip: "Дата создания сообщения об ошибке",
    className: "info-modal__element_title",
    value: fields.BUG_REPORT_DATE,
  },
  {
    id: uuid(),
    title: "Статус",
    tooltip: "Статус может принимать значения: Решено, Не решено",
    className: "info-modal__element_title",
    value: fields.BUG_REPORT_STATUS,
    slotName: "selectStatus",
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Пользователь",
    tooltip: "Пользователь, создавший сообщение",
    className: "info-modal__element_title",
    value: fields.BUG_REPORT_USER,
  },
  {
    id: uuid(),
    isEdited: true,
    title: "Сообщение",
    tooltip: "Текст сообщения",
    className: "info-modal__element_full-size info-modal__about info-modal__element_textarea",
    value: fields.BUG_REPORT_MESSAGE,
    fieldType: "textarea",
    disabledTypes: [ModalType.EDIT, ModalType.READ, ModalType.NEW],
  },
];

export const techElements = [
  {
    id: uuid(),
    title: "Пользователь, изменивший запись",
    tooltip: "Логин пользователя, изменившего запись",
    className: "info-modal__element_title",
    value: fields.BUG_REPORT_UPDATE_USER,
  },
  {
    id: uuid(),
    title: "Дата последнего обновления",
    tooltip: "Дата последнего обновления записи",
    className: "info-modal__element_title",
    value: fields.BUG_REPORT_UPDATE_DATE,
  },
];

export const fullModalElements = [...baseModalElements, ...techElements];
